import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import { usePostCollectRawData } from 'api/assets/usePostCollectRawData';
import { RoleRequired } from 'components/RoleRequired';
import {
  selectActiveAsset,
  selectActiveSensor,
  selectSelectedAssets,
  selectSelectedSensors,
} from 'features/assets-management/store/assetsManagementSlice';
import { UserRole } from 'features/plants-management/models/user.model';
import { useTranslation } from 'languages';
import { useSelector } from 'react-redux';
import { selectPlant } from 'store/accountSlice';

const ColleactRawData = () => {
  const title = useSelector(selectActiveAsset);
  const activeSensor = useSelector(selectActiveSensor);
  const locations = useSelector(selectSelectedSensors);
  const selectedSensor = locations.find((s) => s.sensorId === activeSensor);
  const selectedAsset = useSelector(selectSelectedAssets).find((a) => a.name === title);

  const selectedPlant = useSelector(selectPlant);

  const { mutate: collectRawDataMutation, isPending } = usePostCollectRawData();

  const handleCollectRawData = () => {
    if (selectedAsset && selectedSensor) {
      collectRawDataMutation({
        params: { assetId: selectedAsset.assetId, sensorId: selectedSensor.sensorId },
      });
    }
  };

  const { translate } = useTranslation();

  const componentText = {
    collectRawDataButton: translate('asset_collect_raw_data_button'),
  };

  return (
    <>
      {selectedSensor?.isMonitored && (
        <RoleRequired
          roles={[
            'Super Viewer',
            ...(selectedPlant?.plantSettings.enableLoadMeasurementsForPlantViewers
              ? (['Plant Viewer'] satisfies UserRole[])
              : []),
          ]}
          mode='blacklist'
        >
          <Grid item display='flex' alignItems='center'>
            <LoadingButton
              variant='text'
              size='small'
              color='primary'
              onClick={handleCollectRawData}
              loading={isPending}
            >
              {!isPending && (
                <Typography
                  variant='subtitle1'
                  color='primary'
                  data-testid='assets_management_collect_raw_data_button'
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '.9rem',
                  }}
                >
                  {componentText.collectRawDataButton}
                </Typography>
              )}
            </LoadingButton>
          </Grid>
        </RoleRequired>
      )}
    </>
  );
};

export default ColleactRawData;
