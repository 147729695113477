import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useAuthMutation } from './../queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';
export interface PostCollectRawDataParams {
  assetId: number;
  sensorId: number;
}

export const usePostCollectRawData = () => {
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successToast: translate('successfully_requested_raw_data_toast'),
      errorToast: translate('could_not_request_collect_raw_data_toast'),
    };
  }, []);

  return useAuthMutation<PostCollectRawDataParams>({
    url: (params: PostCollectRawDataParams) =>
      `assets/${params.assetId}/sensors/${params.sensorId}/collect-raw`,
    method: 'POST',
    mutationOptions: {
      onSuccess: (_, payload) => {
        toast.success(componentText.successToast);
        setTimeout(() => {
          queryClient.invalidateQueries({
            predicate: ({ queryKey }) => queryKey.includes('fft-dates'),
          });
        }, 3000);
      },
      onError: () => {
        toast.error(componentText.errorToast);
      },
    },
  });
};
